import React from "react";

class DownloadLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="list">
          <ul>{this.props.compDataContent}</ul>
        </div>
      </>
    );
  }
}

DownloadLayout.propTypes = {};

export default DownloadLayout;
